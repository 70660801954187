'use client'

import Image from 'next/image'

import { cn } from '@/lib/utils'

import KingsCrossFooter from './kings-cross-footer'

type DividerProps = {
  type:
    | 'solid'
    | 'thin'
    | 'thick'
    | 'thick-thin'
    | 'thin-thick'
    | '2-line'
    | '4-line'
    | '5-line'
    | '7-line'
    | 'solid-diamond'
    | 'solid-diamond-inverse'
    | 'outline-diamond'
    | 'shorter-solid-diamond'
    | 'shorter-outline-diamond'
    | 'shorter-2-line'
    | 'scalable-line-diamonds'
    | 'scalable-line-diamonds-black'
    | 'scalable-line-diamonds-vertical'
    | '4-line-left'
    | '7-line-left'
    | 'dots-spreaded'
    | 'kensington'
    | 'kensington-black'
    | 'manchester'
    | 'shorter-single-diamond'
    | 'outline-diamond-white'
    | 'outline-diamond-menu'
    | 'kings-cross-diamonds'
    | 'full-solid-dots'
    | '5-line-thickness-increase'
  className?: string
}

const Divider = ({ type, className }: DividerProps) => {
  switch (type) {
    case 'solid':
      return <hr className={cn('border-t-8 border-black', className)} />

    case 'thin':
      return <hr className={cn('border-t border-black', className)} />

    case 'thick':
      return <hr className={cn('border-t-4 border-black', className)} />

    case 'thick-thin':
      return <hr className={cn('h-2 border-b border-t-4 border-black', className)} />

    case 'thin-thick':
      return <hr className={cn('h-2 border-b-4 border-t border-black', className)} />

    case '2-line':
      return (
        <div className={cn('flex flex-col space-y-[3px]', className)}>
          {[...Array(2)].map((_, i) => (
            <hr key={i} className={cn('border-t-[3px] border-black', className)} />
          ))}
        </div>
      )

    case '4-line':
      return (
        <div className={cn('flex flex-col space-y-[3px]', className)}>
          {[...Array(4)].map((_, i) => (
            <hr key={i} className={cn('border-t-[3px] border-black', className)} />
          ))}
        </div>
      )

    case '5-line':
      return (
        <div className={cn('flex flex-col space-y-[3px]', className)}>
          {[...Array(5)].map((_, i) => (
            <hr key={i} className={cn('border-t-[3px] border-black', className)} />
          ))}
        </div>
      )

    case '7-line':
      return (
        <div className={cn('flex flex-col space-y-[2px]', className)}>
          {[...Array(7)].map((_, i) => (
            <hr key={i} className={cn('border-t', className)} />
          ))}
        </div>
      )

    case '5-line-thickness-increase':
      return (
        <div className={cn('flex flex-col space-y-[3px]', className)}>
          <hr className="border-t-[5px]" />
          <hr className="border-t-[4px]" />
          <hr className="border-t-[3px]" />
          <hr className="border-t-[2px]" />
          <hr className="border-t-[1px]" />
        </div>
      )

    case 'solid-diamond':
      return (
        <div
          className={cn(
            'absolute left-0 top-0 before:absolute before:left-0 before:right-0 before:top-0 before:h-3 before:w-screen before:bg-[url("/assets/icons/diamond-black.svg")] before:bg-center before:bg-repeat-x before:content-[""]',
            className,
          )}
        ></div>
      )

    case 'solid-diamond-inverse':
      return (
        <div
          className={cn(
            'absolute left-0 top-0 before:absolute before:left-0 before:right-0 before:top-0 before:h-3 before:w-screen before:bg-[url("/assets/icons/diamond.svg")] before:content-[""]',
            className,
          )}
        ></div>
      )

    case 'scalable-line-diamonds-vertical':
      return (
        <div className={cn('flex flex-col items-center gap-[10px]', className)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.33333 3.33333C2.30421 4.36246 0 5 0 5C0 5 2.30421 5.63755 3.33333 6.66667C4.36245 7.69579 5 10 5 10C5 10 5.63754 7.69579 6.66667 6.66667C7.69579 5.63755 10 5 10 5C10 5 7.69579 4.36246 6.66667 3.33333C5.63754 2.30421 5 0 5 0C5 0 4.36245 2.30421 3.33333 3.33333Z"
              fill="#C4BFAF"
            />
          </svg>
          <div className="grow border-l border-beige-dark"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.33333 3.33333C2.30421 4.36246 0 5 0 5C0 5 2.30421 5.63755 3.33333 6.66667C4.36245 7.69579 5 10 5 10C5 10 5.63754 7.69579 6.66667 6.66667C7.69579 5.63755 10 5 10 5C10 5 7.69579 4.36246 6.66667 3.33333C5.63754 2.30421 5 0 5 0C5 0 4.36245 2.30421 3.33333 3.33333Z"
              fill="#C4BFAF"
            />
          </svg>
        </div>
      )

    // case 'solid-diamond':
    //   return (
    //     <div
    //       className={cn(
    //         'flex w-full shrink-0 justify-center gap-1.5 overflow-hidden whitespace-nowrap p-px',
    //         className,
    //       )}
    //     >
    //       {[...Array(150)].map((_, i) => (
    //         <div key={i} className="h-[5px] w-[5px] shrink-0 rotate-45 bg-black"></div>
    //       ))}
    //     </div>
    //   )

    // case 'solid-diamond-inverse':
    //   return (
    //     <div
    //       className={cn(
    //         'flex h-[15px] w-full shrink-0 items-center justify-center gap-1.5 overflow-hidden whitespace-nowrap bg-black p-px',
    //         className,
    //       )}
    //     >
    //       {[...Array(150)].map((_, i) => (
    //         <div key={i} className="h-[5px] w-[5px] shrink-0 rotate-45 bg-beige"></div>
    //       ))}
    //     </div>
    //   )

    case 'outline-diamond':
      return (
        <div
          className={cn(
            'flex w-full shrink-0 justify-center gap-[5px] overflow-hidden whitespace-nowrap p-[1.5px]',
            className,
          )}
        >
          {[...Array(150)].map((_, i) => (
            <div
              key={i}
              className="h-[7px] w-[7px] shrink-0 rotate-45 transform border border-black"
            ></div>
          ))}
        </div>
      )

    case 'shorter-solid-diamond':
      return (
        <div className={cn('flex w-full justify-center gap-1.5 p-px', className)}>
          {[...Array(11)].map((_, i) => (
            <div key={i} className="h-[5px] w-[5px] shrink-0 rotate-45 bg-black"></div>
          ))}
        </div>
      )
    case 'shorter-single-diamond':
      return (
        <div className={cn('flex w-full justify-center gap-1.5 p-px', className)}>
          <div className="h-[5px] w-[5px] shrink-0 rotate-45 bg-black"></div>
        </div>
      )

    case 'outline-diamond-white':
    case 'outline-diamond-menu':
      return (
        <div
          className={cn(
            'flex w-full shrink-0 justify-center gap-[5px] overflow-hidden whitespace-nowrap p-[1.5px] small-phone:gap-[6px]',
            className,
          )}
        >
          {[...Array(150)].map((_, i) => (
            <div
              key={i}
              className={cn(
                'h-[6px] w-[6px] shrink-0 rotate-45 transform border border-white md:h-[7px] md:w-[7px]',
                {
                  'border-red-menu': type === 'outline-diamond-menu',
                },
              )}
            ></div>
          ))}
        </div>
      )

    case 'shorter-outline-diamond':
      return (
        <div className={cn('flex w-full justify-center gap-[0.3rem] p-[1.5px]', className)}>
          {[...Array(10)].map((_, i) => (
            <div key={i} className="h-[7px] w-[7px] shrink-0 rotate-45 border border-black"></div>
          ))}
        </div>
      )

    case 'shorter-2-line':
      return (
        <div className={cn('flex flex-col space-y-[2px]', className)}>
          {[...Array(2)].map((_, i) => (
            <hr key={i} className="border-t-[2px]" />
          ))}
        </div>
      )

    case '4-line-left':
      return (
        <div className="flex h-full w-full">
          <div className={cn('mr-[2px] h-full w-[3px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
        </div>
      )

    case '7-line-left':
      return (
        <div className="flex h-full w-full">
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
          <div className={cn('mr-[2px] h-full w-[1px] bg-black', className)}></div>
        </div>
      )

    case 'scalable-line-diamonds':
      return (
        <div className={cn('flex items-center gap-[10px]', className)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.33333 3.33333C2.30421 4.36246 0 5 0 5C0 5 2.30421 5.63755 3.33333 6.66667C4.36245 7.69579 5 10 5 10C5 10 5.63754 7.69579 6.66667 6.66667C7.69579 5.63755 10 5 10 5C10 5 7.69579 4.36246 6.66667 3.33333C5.63754 2.30421 5 0 5 0C5 0 4.36245 2.30421 3.33333 3.33333Z"
              fill="#C4BFAF"
            />
          </svg>
          <div className="grow border-b border-beige-dark"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.33333 3.33333C2.30421 4.36246 0 5 0 5C0 5 2.30421 5.63755 3.33333 6.66667C4.36245 7.69579 5 10 5 10C5 10 5.63754 7.69579 6.66667 6.66667C7.69579 5.63755 10 5 10 5C10 5 7.69579 4.36246 6.66667 3.33333C5.63754 2.30421 5 0 5 0C5 0 4.36245 2.30421 3.33333 3.33333Z"
              fill="#C4BFAF"
            />
          </svg>
        </div>
      )

    case 'scalable-line-diamonds-black':
      return (
        <div className={cn('flex items-center gap-[10px]', className)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.33333 3.33333C2.30421 4.36246 0 5 0 5C0 5 2.30421 5.63755 3.33333 6.66667C4.36245 7.69579 5 10 5 10C5 10 5.63754 7.69579 6.66667 6.66667C7.69579 5.63755 10 5 10 5C10 5 7.69579 4.36246 6.66667 3.33333C5.63754 2.30421 5 0 5 0C5 0 4.36245 2.30421 3.33333 3.33333Z"
              fill="#353839"
            />
          </svg>
          <div className="grow border-b border-black"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <path
              d="M3.33333 3.33333C2.30421 4.36246 0 5 0 5C0 5 2.30421 5.63755 3.33333 6.66667C4.36245 7.69579 5 10 5 10C5 10 5.63754 7.69579 6.66667 6.66667C7.69579 5.63755 10 5 10 5C10 5 7.69579 4.36246 6.66667 3.33333C5.63754 2.30421 5 0 5 0C5 0 4.36245 2.30421 3.33333 3.33333Z"
              fill="#353839"
            />
          </svg>
        </div>
      )

    case 'dots-spreaded':
      return (
        <div className="flex w-full gap-[42px] overflow-hidden">
          {[...Array(10)].map((_, i) => (
            <div className="flex gap-1.5" key={i}>
              <div className="h-1 w-1 rounded-full bg-black"></div>
              <div className="h-1 w-1 rounded-full bg-black"></div>
              <div className="h-1 w-1 rounded-full bg-black"></div>
            </div>
          ))}
        </div>
      )
    case 'full-solid-dots':
      return (
        <div className="absolute left-0 top-0 h-3 w-full">
          <div
            className='h-full bg-[url("/assets/icons/black-dot.svg")] bg-repeat-x'
            style={{
              backgroundSize: '12px 8px',
            }}
          ></div>
        </div>
      )

    case 'kensington':
      return (
        <div className={cn('overflow-hidden', className)}>
          <div className="h-[34px] bg-[url(/assets/icons/kensington/kensington-ornament.svg)] bg-[length:auto_100%] bg-repeat-x md:-ml-8 md:h-[60px]"></div>
          <div className="-ml-5 -mt-2 h-[34px] bg-[url(/assets/icons/kensington/kensington-ornament.svg)] bg-[length:auto_100%] bg-repeat-x md:-ml-0 md:-mt-4 md:h-[60px]"></div>
          <div className="-mt-2 h-[34px] bg-[url(/assets/icons/kensington/kensington-ornament.svg)] bg-[length:auto_100%] bg-repeat-x md:-ml-8 md:-mt-4 md:h-[60px]"></div>
        </div>
      )

    case 'kensington-black':
      return (
        <div className={className}>
          <div className="-ml-5 h-[25px] bg-[url(/assets/icons/kensington/kensington-ornament-mobile-black.svg)] md:-ml-8 md:h-[60px] md:bg-[url(/assets/icons/kensington/kensington-ornament-black.svg)]"></div>
          <div className="-mt-1 h-[25px] bg-[url(/assets/icons/kensington/kensington-ornament-mobile-black.svg)] md:-mt-2 md:h-[60px] md:bg-[url(/assets/icons/kensington/kensington-ornament-black.svg)]"></div>
          <div className="-ml-5 -mt-1 h-[25px] bg-[url(/assets/icons/kensington/kensington-ornament-mobile-black.svg)] md:-ml-8 md:-mt-2 md:h-[60px] md:bg-[url(/assets/icons/kensington/kensington-ornament-black.svg)]"></div>
        </div>
      )

    case 'kings-cross-diamonds':
      return <KingsCrossFooter />

    default:
      return null
  }
}

export default Divider
