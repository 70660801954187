'use client'

import React, { useState } from 'react'

const KingsCrossFooter = () => {
  const [filledPaths, setFilledPaths] = useState<number[]>([])

  const handleMouseEnter = (idx: number) => {
    if (!filledPaths.includes(idx)) {
      setFilledPaths([...filledPaths, idx])
    }
  }

  const paths = [
    {
      d: 'M-19.7537 40.5447L-2.33664 30.4964L15.0804 40.5447V60.6406L-2.33664 70.6889L-19.7537 60.6406V40.5447Z',
    },
    {
      d: 'M-19.7537 100.26L-2.33664 90.2121L15.0804 100.26V120.356L-2.33664 130.405L-19.7537 120.356V100.26Z',
    },
    {
      d: 'M-19.7537 159.976L-2.33664 149.928L15.0804 159.976V180.072L-2.33664 190.12L-19.7537 180.072V159.976Z',
    },
    {
      d: 'M-2.61309 10.6867L14.804 0.638343L32.2211 10.6867V30.7826L14.804 40.8309L-2.61309 30.7826V10.6867Z',
    },
    {
      d: 'M-2.61309 70.4025L14.804 60.3542L32.2211 70.4025V90.4984L14.804 100.547L-2.61309 90.4984V70.4025Z',
    },
    {
      d: 'M-2.61309 130.118L14.804 120.07L32.2211 130.118V150.214L14.804 160.262L-2.61309 150.214V130.118Z',
    },
    {
      d: 'M15.0803 40.5447L32.4973 30.4964L49.9144 40.5447V60.6406L32.4973 70.6889L15.0803 60.6406V40.5447Z',
    },
    {
      d: 'M15.0803 100.26L32.4973 90.2121L49.9144 100.26V120.356L32.4973 130.405L15.0803 120.356V100.26Z',
    },
    {
      d: 'M15.0803 159.976L32.4973 149.928L49.9144 159.976V180.072L32.4973 190.12L15.0803 180.072V159.976Z',
    },
    {
      d: 'M32.2209 10.6867L49.638 0.638343L67.0551 10.6867V30.7826L49.638 40.8309L32.2209 30.7826V10.6867Z',
    },
    {
      d: 'M32.2209 70.4025L49.638 60.3542L67.0551 70.4025V90.4984L49.638 100.547L32.2209 90.4984V70.4025Z',
    },
    {
      d: 'M32.2209 130.118L49.638 120.07L67.0551 130.118V150.214L49.638 160.262L32.2209 150.214V130.118Z',
    },
    {
      d: 'M49.9143 40.5447L67.3313 30.4964L84.7484 40.5447V60.6406L67.3313 70.6889L49.9143 60.6406V40.5447Z',
    },
    {
      d: 'M49.9143 100.26L67.3313 90.2121L84.7484 100.26V120.356L67.3313 130.405L49.9143 120.356V100.26Z',
    },
    {
      d: 'M49.9143 159.976L67.3313 149.928L84.7484 159.976V180.072L67.3313 190.12L49.9143 180.072V159.976Z',
    },
    {
      d: 'M67.0549 10.6867L84.472 0.638343L101.889 10.6867V30.7826L84.472 40.8309L67.0549 30.7826V10.6867Z',
    },
    {
      d: 'M67.0549 70.4025L84.472 60.3542L101.889 70.4025V90.4984L84.472 100.547L67.0549 90.4984V70.4025Z',
    },
    {
      d: 'M67.0549 130.118L84.472 120.07L101.889 130.118V150.214L84.472 160.262L67.0549 150.214V130.118Z',
    },
    {
      d: 'M84.7482 40.5447L102.165 30.4964L119.582 40.5447V60.6406L102.165 70.6889L84.7482 60.6406V40.5447Z',
    },
    {
      d: 'M84.7482 100.26L102.165 90.2121L119.582 100.26V120.356L102.165 130.405L84.7482 120.356V100.26Z',
    },
    {
      d: 'M84.7482 159.976L102.165 149.928L119.582 159.976V180.072L102.165 190.12L84.7482 180.072V159.976Z',
    },
    {
      d: 'M101.889 10.6867L119.306 0.638343L136.723 10.6867V30.7826L119.306 40.8309L101.889 30.7826V10.6867Z',
    },
    {
      d: 'M101.889 70.4025L119.306 60.3542L136.723 70.4025V90.4984L119.306 100.547L101.889 90.4984V70.4025Z',
    },
    {
      d: 'M101.889 130.118L119.306 120.07L136.723 130.118V150.214L119.306 160.262L101.889 150.214V130.118Z',
    },
    {
      d: 'M119.582 40.5447L136.999 30.4964L154.416 40.5447V60.6406L136.999 70.6889L119.582 60.6406V40.5447Z',
    },
    {
      d: 'M119.582 100.26L136.999 90.2121L154.416 100.26V120.356L136.999 130.405L119.582 120.356V100.26Z',
    },
    {
      d: 'M119.582 159.976L136.999 149.928L154.416 159.976V180.072L136.999 190.12L119.582 180.072V159.976Z',
    },
    {
      d: 'M136.723 10.6867L154.14 0.638343L171.557 10.6867V30.7826L154.14 40.8309L136.723 30.7826V10.6867Z',
    },
    {
      d: 'M136.723 70.4025L154.14 60.3542L171.557 70.4025V90.4984L154.14 100.547L136.723 90.4984V70.4025Z',
    },
    {
      d: 'M136.723 130.118L154.14 120.07L171.557 130.118V150.214L154.14 160.262L136.723 150.214V130.118Z',
    },
    {
      d: 'M154.416 40.5447L171.833 30.4964L189.25 40.5447V60.6406L171.833 70.6889L154.416 60.6406V40.5447Z',
    },
    {
      d: 'M154.416 100.26L171.833 90.2121L189.25 100.26V120.356L171.833 130.405L154.416 120.356V100.26Z',
    },
    {
      d: 'M154.416 159.976L171.833 149.928L189.25 159.976V180.072L171.833 190.12L154.416 180.072V159.976Z',
    },
    {
      d: 'M171.559 10.6867L188.976 0.638343L206.393 10.6867V30.7826L188.976 40.8309L171.559 30.7826V10.6867Z',
    },
    {
      d: 'M171.559 70.4025L188.976 60.3542L206.393 70.4025V90.4984L188.976 100.547L171.559 90.4984V70.4025Z',
    },
    {
      d: 'M171.559 130.118L188.976 120.07L206.393 130.118V150.214L188.976 160.262L171.559 150.214V130.118Z',
    },
    {
      d: 'M189.252 40.5447L206.669 30.4964L224.086 40.5447V60.6406L206.669 70.6889L189.252 60.6406V40.5447Z',
    },
    {
      d: 'M189.252 100.26L206.669 90.2121L224.086 100.26V120.356L206.669 130.405L189.252 120.356V100.26Z',
    },
    {
      d: 'M189.252 159.976L206.669 149.928L224.086 159.976V180.072L206.669 190.12L189.252 180.072V159.976Z',
    },
    {
      d: 'M206.393 10.6867L223.81 0.638343L241.227 10.6867V30.7826L223.81 40.8309L206.393 30.7826V10.6867Z',
    },
    {
      d: 'M206.393 70.4025L223.81 60.3542L241.227 70.4025V90.4984L223.81 100.547L206.393 90.4984V70.4025Z',
    },
    {
      d: 'M206.393 130.118L223.81 120.07L241.227 130.118V150.214L223.81 160.262L206.393 150.214V130.118Z',
    },
    {
      d: 'M224.086 40.5447L241.503 30.4964L258.92 40.5447V60.6406L241.503 70.6889L224.086 60.6406V40.5447Z',
    },
    {
      d: 'M224.086 100.26L241.503 90.2121L258.92 100.26V120.356L241.503 130.405L224.086 120.356V100.26Z',
    },
    {
      d: 'M224.086 159.976L241.503 149.928L258.92 159.976V180.072L241.503 190.12L224.086 180.072V159.976Z',
    },
    {
      d: 'M241.227 10.6867L258.644 0.638343L276.061 10.6867V30.7826L258.644 40.8309L241.227 30.7826V10.6867Z',
    },
    {
      d: 'M241.227 70.4025L258.644 60.3542L276.061 70.4025V90.4984L258.644 100.547L241.227 90.4984V70.4025Z',
    },
    {
      d: 'M241.227 130.118L258.644 120.07L276.061 130.118V150.214L258.644 160.262L241.227 150.214V130.118Z',
    },
    {
      d: 'M258.92 40.5447L276.337 30.4964L293.754 40.5447V60.6406L276.337 70.6889L258.92 60.6406V40.5447Z',
    },
    {
      d: 'M258.92 100.26L276.337 90.2121L293.754 100.26V120.356L276.337 130.405L258.92 120.356V100.26Z',
    },
    {
      d: 'M258.92 159.976L276.337 149.928L293.754 159.976V180.072L276.337 190.12L258.92 180.072V159.976Z',
    },
    {
      d: 'M276.061 10.6867L293.478 0.638343L310.895 10.6867V30.7826L293.478 40.8309L276.061 30.7826V10.6867Z',
    },
    {
      d: 'M276.061 70.4025L293.478 60.3542L310.895 70.4025V90.4984L293.478 100.547L276.061 90.4984V70.4025Z',
    },
    {
      d: 'M276.061 130.118L293.478 120.07L310.895 130.118V150.214L293.478 160.262L276.061 150.214V130.118Z',
    },
    {
      d: 'M293.754 40.5447L311.171 30.4964L328.588 40.5447V60.6406L311.171 70.6889L293.754 60.6406V40.5447Z',
    },
    {
      d: 'M293.754 100.26L311.171 90.2121L328.588 100.26V120.356L311.171 130.405L293.754 120.356V100.26Z',
    },
    {
      d: 'M293.754 159.976L311.171 149.928L328.588 159.976V180.072L311.171 190.12L293.754 180.072V159.976Z',
    },
    {
      d: 'M310.895 10.6867L328.312 0.638343L345.729 10.6867V30.7826L328.312 40.8309L310.895 30.7826V10.6867Z',
    },
    {
      d: 'M310.895 70.4025L328.312 60.3542L345.729 70.4025V90.4984L328.312 100.547L310.895 90.4984V70.4025Z',
    },
    {
      d: 'M310.895 130.118L328.312 120.07L345.729 130.118V150.214L328.312 160.262L310.895 150.214V130.118Z',
    },
    {
      d: 'M328.588 40.5447L346.005 30.4964L363.422 40.5447V60.6406L346.005 70.6889L328.588 60.6406V40.5447Z',
    },
    {
      d: 'M328.588 100.26L346.005 90.2121L363.422 100.26V120.356L346.005 130.405L328.588 120.356V100.26Z',
    },
    {
      d: 'M328.588 159.976L346.005 149.928L363.422 159.976V180.072L346.005 190.12L328.588 180.072V159.976Z',
    },
    {
      d: 'M345.729 10.6867L363.146 0.638343L380.563 10.6867V30.7826L363.146 40.8309L345.729 30.7826V10.6867Z',
    },
    {
      d: 'M345.729 70.4025L363.146 60.3542L380.563 70.4025V90.4984L363.146 100.547L345.729 90.4984V70.4025Z',
    },
    {
      d: 'M345.729 130.118L363.146 120.07L380.563 130.118V150.214L363.146 160.262L345.729 150.214V130.118Z',
    },
    {
      d: 'M363.422 40.5447L380.839 30.4964L398.256 40.5447V60.6406L380.839 70.6889L363.422 60.6406V40.5447Z',
    },
    {
      d: 'M363.422 100.26L380.839 90.2121L398.256 100.26V120.356L380.839 130.405L363.422 120.356V100.26Z',
    },
    {
      d: 'M363.422 159.976L380.839 149.928L398.256 159.976V180.072L380.839 190.12L363.422 180.072V159.976Z',
    },
    {
      d: 'M380.563 10.6867L397.98 0.638343L415.397 10.6867V30.7826L397.98 40.8309L380.563 30.7826V10.6867Z',
    },
    {
      d: 'M380.563 70.4025L397.98 60.3542L415.397 70.4025V90.4984L397.98 100.547L380.563 90.4984V70.4025Z',
    },
    {
      d: 'M380.563 130.118L397.98 120.07L415.397 130.118V150.214L397.98 160.262L380.563 150.214V130.118Z',
    },
    {
      d: 'M398.256 40.5447L415.673 30.4964L433.09 40.5447V60.6406L415.673 70.6889L398.256 60.6406V40.5447Z',
    },
    {
      d: 'M398.256 100.26L415.673 90.2121L433.09 100.26V120.356L415.673 130.405L398.256 120.356V100.26Z',
    },
    {
      d: 'M398.256 159.976L415.673 149.928L433.09 159.976V180.072L415.673 190.12L398.256 180.072V159.976Z',
    },
    {
      d: 'M415.397 10.6867L432.814 0.638343L450.231 10.6867V30.7826L432.814 40.8309L415.397 30.7826V10.6867Z',
    },
    {
      d: 'M415.397 70.4025L432.814 60.3542L450.231 70.4025V90.4984L432.814 100.547L415.397 90.4984V70.4025Z',
    },
    {
      d: 'M415.397 130.118L432.814 120.07L450.231 130.118V150.214L432.814 160.262L415.397 150.214V130.118Z',
    },
    {
      d: 'M433.09 40.5447L450.507 30.4964L467.924 40.5447V60.6406L450.507 70.6889L433.09 60.6406V40.5447Z',
    },
    {
      d: 'M433.09 100.26L450.507 90.2121L467.924 100.26V120.356L450.507 130.405L433.09 120.356V100.26Z',
    },
    {
      d: 'M433.09 159.976L450.507 149.928L467.924 159.976V180.072L450.507 190.12L433.09 180.072V159.976Z',
    },
    {
      d: 'M450.231 10.6867L467.648 0.638343L485.065 10.6867V30.7826L467.648 40.8309L450.231 30.7826V10.6867Z',
    },
    {
      d: 'M450.231 70.4025L467.648 60.3542L485.065 70.4025V90.4984L467.648 100.547L450.231 90.4984V70.4025Z',
    },
    {
      d: 'M450.231 130.118L467.648 120.07L485.065 130.118V150.214L467.648 160.262L450.231 150.214V130.118Z',
    },
    {
      d: 'M467.924 40.5447L485.341 30.4964L502.758 40.5447V60.6406L485.341 70.6889L467.924 60.6406V40.5447Z',
    },
    {
      d: 'M467.924 100.26L485.341 90.2121L502.758 100.26V120.356L485.341 130.405L467.924 120.356V100.26Z',
    },
    {
      d: 'M467.924 159.976L485.341 149.928L502.758 159.976V180.072L485.341 190.12L467.924 180.072V159.976Z',
    },
    {
      d: 'M485.065 10.6867L502.482 0.638343L519.899 10.6867V30.7826L502.482 40.8309L485.065 30.7826V10.6867Z',
    },
    {
      d: 'M485.065 70.4025L502.482 60.3542L519.899 70.4025V90.4984L502.482 100.547L485.065 90.4984V70.4025Z',
    },
    {
      d: 'M485.065 130.118L502.482 120.07L519.899 130.118V150.214L502.482 160.262L485.065 150.214V130.118Z',
    },
    {
      d: 'M502.758 40.5447L520.175 30.4964L537.592 40.5447V60.6406L520.175 70.6889L502.758 60.6406V40.5447Z',
    },
    {
      d: 'M502.758 100.26L520.175 90.2121L537.592 100.26V120.356L520.175 130.405L502.758 120.356V100.26Z',
    },
    {
      d: 'M502.758 159.976L520.175 149.928L537.592 159.976V180.072L520.175 190.12L502.758 180.072V159.976Z',
    },
    {
      d: 'M519.899 10.6867L537.316 0.638343L554.733 10.6867V30.7826L537.316 40.8309L519.899 30.7826V10.6867Z',
    },
    {
      d: 'M519.899 70.4025L537.316 60.3542L554.733 70.4025V90.4984L537.316 100.547L519.899 90.4984V70.4025Z',
    },
    {
      d: 'M519.899 130.118L537.316 120.07L554.733 130.118V150.214L537.316 160.262L519.899 150.214V130.118Z',
    },
    {
      d: 'M537.592 40.5447L555.009 30.4964L572.426 40.5447V60.6406L555.009 70.6889L537.592 60.6406V40.5447Z',
    },
    {
      d: 'M537.592 100.26L555.009 90.2121L572.426 100.26V120.356L555.009 130.405L537.592 120.356V100.26Z',
    },
    {
      d: 'M537.592 159.976L555.009 149.928L572.426 159.976V180.072L555.009 190.12L537.592 180.072V159.976Z',
    },
    {
      d: 'M554.735 10.6867L572.152 0.638343L589.569 10.6867V30.7826L572.152 40.8309L554.735 30.7826V10.6867Z',
    },
    {
      d: 'M554.735 70.4025L572.152 60.3542L589.569 70.4025V90.4984L572.152 100.547L554.735 90.4984V70.4025Z',
    },
    {
      d: 'M554.735 130.118L572.152 120.07L589.569 130.118V150.214L572.152 160.262L554.735 150.214V130.118Z',
    },
    {
      d: 'M572.428 40.5447L589.845 30.4964L607.262 40.5447V60.6406L589.845 70.6889L572.428 60.6406V40.5447Z',
    },
    {
      d: 'M572.428 100.26L589.845 90.2121L607.262 100.26V120.356L589.845 130.405L572.428 120.356V100.26Z',
    },
    {
      d: 'M572.428 159.976L589.845 149.928L607.262 159.976V180.072L589.845 190.12L572.428 180.072V159.976Z',
    },
    {
      d: 'M589.569 10.6867L606.986 0.638343L624.403 10.6867V30.7826L606.986 40.8309L589.569 30.7826V10.6867Z',
    },
    {
      d: 'M589.569 70.4025L606.986 60.3542L624.403 70.4025V90.4984L606.986 100.547L589.569 90.4984V70.4025Z',
    },
    {
      d: 'M589.569 130.118L606.986 120.07L624.403 130.118V150.214L606.986 160.262L589.569 150.214V130.118Z',
    },
    {
      d: 'M607.262 40.5447L624.679 30.4964L642.096 40.5447V60.6406L624.679 70.6889L607.262 60.6406V40.5447Z',
    },
    {
      d: 'M607.262 100.26L624.679 90.2121L642.096 100.26V120.356L624.679 130.405L607.262 120.356V100.26Z',
    },
    {
      d: 'M607.262 159.976L624.679 149.928L642.096 159.976V180.072L624.679 190.12L607.262 180.072V159.976Z',
    },
    {
      d: 'M624.403 10.6867L641.82 0.638343L659.237 10.6867V30.7826L641.82 40.8309L624.403 30.7826V10.6867Z',
    },
    {
      d: 'M624.403 70.4025L641.82 60.3542L659.237 70.4025V90.4984L641.82 100.547L624.403 90.4984V70.4025Z',
    },
    {
      d: 'M624.403 130.118L641.82 120.07L659.237 130.118V150.214L641.82 160.262L624.403 150.214V130.118Z',
    },
    {
      d: 'M642.096 40.5447L659.513 30.4964L676.93 40.5447V60.6406L659.513 70.6889L642.096 60.6406V40.5447Z',
    },
    {
      d: 'M642.096 100.26L659.513 90.2121L676.93 100.26V120.356L659.513 130.405L642.096 120.356V100.26Z',
    },
    {
      d: 'M642.096 159.976L659.513 149.928L676.93 159.976V180.072L659.513 190.12L642.096 180.072V159.976Z',
    },
    {
      d: 'M659.237 10.6867L676.654 0.638343L694.071 10.6867V30.7826L676.654 40.8309L659.237 30.7826V10.6867Z',
    },
    {
      d: 'M659.237 70.4025L676.654 60.3542L694.071 70.4025V90.4984L676.654 100.547L659.237 90.4984V70.4025Z',
    },
    {
      d: 'M659.237 130.118L676.654 120.07L694.071 130.118V150.214L676.654 160.262L659.237 150.214V130.118Z',
    },
    {
      d: 'M676.93 40.5447L694.347 30.4964L711.764 40.5447V60.6406L694.347 70.6889L676.93 60.6406V40.5447Z',
    },
    {
      d: 'M676.93 100.26L694.347 90.2121L711.764 100.26V120.356L694.347 130.405L676.93 120.356V100.26Z',
    },
    {
      d: 'M676.93 159.976L694.347 149.928L711.764 159.976V180.072L694.347 190.12L676.93 180.072V159.976Z',
    },
    {
      d: 'M694.071 10.6867L711.488 0.638343L728.905 10.6867V30.7826L711.488 40.8309L694.071 30.7826V10.6867Z',
    },
    {
      d: 'M694.071 70.4025L711.488 60.3542L728.905 70.4025V90.4984L711.488 100.547L694.071 90.4984V70.4025Z',
    },
    {
      d: 'M694.071 130.118L711.488 120.07L728.905 130.118V150.214L711.488 160.262L694.071 150.214V130.118Z',
    },
    {
      d: 'M711.764 40.5447L729.181 30.4964L746.598 40.5447V60.6406L729.181 70.6889L711.764 60.6406V40.5447Z',
    },
    {
      d: 'M711.764 100.26L729.181 90.2121L746.598 100.26V120.356L729.181 130.405L711.764 120.356V100.26Z',
    },
    {
      d: 'M711.764 159.976L729.181 149.928L746.598 159.976V180.072L729.181 190.12L711.764 180.072V159.976Z',
    },
    {
      d: 'M728.904 10.6867L746.322 0.638343L763.739 10.6867V30.7826L746.322 40.8309L728.904 30.7826V10.6867Z',
    },
    {
      d: 'M728.904 70.4025L746.322 60.3542L763.739 70.4025V90.4984L746.322 100.547L728.904 90.4984V70.4025Z',
    },
    {
      d: 'M728.904 130.118L746.322 120.07L763.739 130.118V150.214L746.322 160.262L728.904 150.214V130.118Z',
    },
    {
      d: 'M746.598 40.5447L764.015 30.4964L781.432 40.5447V60.6406L764.015 70.6889L746.598 60.6406V40.5447Z',
    },
    {
      d: 'M746.598 100.26L764.015 90.2121L781.432 100.26V120.356L764.015 130.405L746.598 120.356V100.26Z',
    },
    {
      d: 'M746.598 159.976L764.015 149.928L781.432 159.976V180.072L764.015 190.12L746.598 180.072V159.976Z',
    },
    {
      d: 'M763.738 10.6867L781.156 0.638343L798.573 10.6867V30.7826L781.156 40.8309L763.738 30.7826V10.6867Z',
    },
    {
      d: 'M763.738 70.4025L781.156 60.3542L798.573 70.4025V90.4984L781.156 100.547L763.738 90.4984V70.4025Z',
    },
    {
      d: 'M763.738 130.118L781.156 120.07L798.573 130.118V150.214L781.156 160.262L763.738 150.214V130.118Z',
    },
    {
      d: 'M781.432 40.5447L798.849 30.4964L816.266 40.5447V60.6406L798.849 70.6889L781.432 60.6406V40.5447Z',
    },
    {
      d: 'M781.432 100.26L798.849 90.2121L816.266 100.26V120.356L798.849 130.405L781.432 120.356V100.26Z',
    },
    {
      d: 'M781.432 159.976L798.849 149.928L816.266 159.976V180.072L798.849 190.12L781.432 180.072V159.976Z',
    },
    {
      d: 'M798.572 10.6867L815.99 0.638343L833.407 10.6867V30.7826L815.99 40.8309L798.572 30.7826V10.6867Z',
    },
    {
      d: 'M798.572 70.4025L815.99 60.3542L833.407 70.4025V90.4984L815.99 100.547L798.572 90.4984V70.4025Z',
    },
    {
      d: 'M798.572 130.118L815.99 120.07L833.407 130.118V150.214L815.99 160.262L798.572 150.214V130.118Z',
    },
    {
      d: 'M816.266 40.5447L833.683 30.4964L851.1 40.5447V60.6406L833.683 70.6889L816.266 60.6406V40.5447Z',
    },
    {
      d: 'M816.266 100.26L833.683 90.2121L851.1 100.26V120.356L833.683 130.405L816.266 120.356V100.26Z',
    },
    {
      d: 'M816.266 159.976L833.683 149.928L851.1 159.976V180.072L833.683 190.12L816.266 180.072V159.976Z',
    },
    {
      d: 'M833.406 10.6867L850.824 0.638343L868.241 10.6867V30.7826L850.824 40.8309L833.406 30.7826V10.6867Z',
    },
    {
      d: 'M833.406 70.4025L850.824 60.3542L868.241 70.4025V90.4984L850.824 100.547L833.406 90.4984V70.4025Z',
    },
    {
      d: 'M833.406 130.118L850.824 120.07L868.241 130.118V150.214L850.824 160.262L833.406 150.214V130.118Z',
    },
    {
      d: 'M851.1 40.5447L868.517 30.4964L885.934 40.5447V60.6406L868.517 70.6889L851.1 60.6406V40.5447Z',
    },
    {
      d: 'M851.1 100.26L868.517 90.2121L885.934 100.26V120.356L868.517 130.405L851.1 120.356V100.26Z',
    },
    {
      d: 'M851.1 159.976L868.517 149.928L885.934 159.976V180.072L868.517 190.12L851.1 180.072V159.976Z',
    },
    {
      d: 'M868.24 10.6867L885.657 0.638343L903.075 10.6867V30.7826L885.657 40.8309L868.24 30.7826V10.6867Z',
    },
    {
      d: 'M868.24 70.4025L885.657 60.3542L903.075 70.4025V90.4984L885.657 100.547L868.24 90.4984V70.4025Z',
    },
    {
      d: 'M868.24 130.118L885.657 120.07L903.075 130.118V150.214L885.657 160.262L868.24 150.214V130.118Z',
    },
    {
      d: 'M885.934 40.5447L903.351 30.4964L920.768 40.5447V60.6406L903.351 70.6889L885.934 60.6406V40.5447Z',
    },
    {
      d: 'M885.934 100.26L903.351 90.2121L920.768 100.26V120.356L903.351 130.405L885.934 120.356V100.26Z',
    },
    {
      d: 'M885.934 159.976L903.351 149.928L920.768 159.976V180.072L903.351 190.12L885.934 180.072V159.976Z',
    },
    {
      d: 'M903.076 10.6867L920.493 0.638343L937.911 10.6867V30.7826L920.493 40.8309L903.076 30.7826V10.6867Z',
    },
    {
      d: 'M903.076 70.4025L920.493 60.3542L937.911 70.4025V90.4984L920.493 100.547L903.076 90.4984V70.4025Z',
    },
    {
      d: 'M903.076 130.118L920.493 120.07L937.911 130.118V150.214L920.493 160.262L903.076 150.214V130.118Z',
    },
    {
      d: 'M920.77 40.5447L938.187 30.4964L955.604 40.5447V60.6406L938.187 70.6889L920.77 60.6406V40.5447Z',
    },
    {
      d: 'M920.77 100.26L938.187 90.2121L955.604 100.26V120.356L938.187 130.405L920.77 120.356V100.26Z',
    },
    {
      d: 'M920.77 159.976L938.187 149.928L955.604 159.976V180.072L938.187 190.12L920.77 180.072V159.976Z',
    },
    {
      d: 'M937.91 10.6867L955.327 0.638343L972.745 10.6867V30.7826L955.327 40.8309L937.91 30.7826V10.6867Z',
    },
    {
      d: 'M937.91 70.4025L955.327 60.3542L972.745 70.4025V90.4984L955.327 100.547L937.91 90.4984V70.4025Z',
    },
    {
      d: 'M937.91 130.118L955.327 120.07L972.745 130.118V150.214L955.327 160.262L937.91 150.214V130.118Z',
    },
    {
      d: 'M955.604 40.5447L973.021 30.4964L990.438 40.5447V60.6406L973.021 70.6889L955.604 60.6406V40.5447Z',
    },
    {
      d: 'M955.604 100.26L973.021 90.2121L990.438 100.26V120.356L973.021 130.405L955.604 120.356V100.26Z',
    },
    {
      d: 'M955.604 159.976L973.021 149.928L990.438 159.976V180.072L973.021 190.12L955.604 180.072V159.976Z',
    },
    {
      d: 'M972.744 10.6867L990.161 0.638343L1007.58 10.6867V30.7826L990.161 40.8309L972.744 30.7826V10.6867Z',
    },
    {
      d: 'M972.744 70.4025L990.161 60.3542L1007.58 70.4025V90.4984L990.161 100.547L972.744 90.4984V70.4025Z',
    },
    {
      d: 'M972.744 130.118L990.161 120.07L1007.58 130.118V150.214L990.161 160.262L972.744 150.214V130.118Z',
    },
    {
      d: 'M990.438 40.5447L1007.85 30.4964L1025.27 40.5447V60.6406L1007.85 70.6889L990.438 60.6406V40.5447Z',
    },
    {
      d: 'M990.438 100.26L1007.85 90.2121L1025.27 100.26V120.356L1007.85 130.405L990.438 120.356V100.26Z',
    },
    {
      d: 'M990.438 159.976L1007.85 149.928L1025.27 159.976V180.072L1007.85 190.12L990.438 180.072V159.976Z',
    },
    {
      d: 'M1007.58 10.6867L1025 0.638343L1042.41 10.6867V30.7826L1025 40.8309L1007.58 30.7826V10.6867Z',
    },
    {
      d: 'M1007.58 70.4025L1025 60.3542L1042.41 70.4025V90.4984L1025 100.547L1007.58 90.4984V70.4025Z',
    },
    {
      d: 'M1007.58 130.118L1025 120.07L1042.41 130.118V150.214L1025 160.262L1007.58 150.214V130.118Z',
    },
    {
      d: 'M1025.27 40.5447L1042.69 30.4964L1060.11 40.5447V60.6406L1042.69 70.6889L1025.27 60.6406V40.5447Z',
    },
    {
      d: 'M1025.27 100.26L1042.69 90.2121L1060.11 100.26V120.356L1042.69 130.405L1025.27 120.356V100.26Z',
    },
    {
      d: 'M1025.27 159.976L1042.69 149.928L1060.11 159.976V180.072L1042.69 190.12L1025.27 180.072V159.976Z',
    },
    {
      d: 'M1042.41 10.6867L1059.83 0.638343L1077.25 10.6867V30.7826L1059.83 40.8309L1042.41 30.7826V10.6867Z',
    },
    {
      d: 'M1042.41 70.4025L1059.83 60.3542L1077.25 70.4025V90.4984L1059.83 100.547L1042.41 90.4984V70.4025Z',
    },
    {
      d: 'M1042.41 130.118L1059.83 120.07L1077.25 130.118V150.214L1059.83 160.262L1042.41 150.214V130.118Z',
    },
    {
      d: 'M1060.11 40.5447L1077.52 30.4964L1094.94 40.5447V60.6406L1077.52 70.6889L1060.11 60.6406V40.5447Z',
    },
    {
      d: 'M1060.11 100.26L1077.52 90.2121L1094.94 100.26V120.356L1077.52 130.405L1060.11 120.356V100.26Z',
    },
    {
      d: 'M1060.11 159.976L1077.52 149.928L1094.94 159.976V180.072L1077.52 190.12L1060.11 180.072V159.976Z',
    },
    {
      d: 'M1077.25 10.6867L1094.66 0.638343L1112.08 10.6867V30.7826L1094.66 40.8309L1077.25 30.7826V10.6867Z',
    },
    {
      d: 'M1077.25 70.4025L1094.66 60.3542L1112.08 70.4025V90.4984L1094.66 100.547L1077.25 90.4984V70.4025Z',
    },
    {
      d: 'M1077.25 130.118L1094.66 120.07L1112.08 130.118V150.214L1094.66 160.262L1077.25 150.214V130.118Z',
    },
    {
      d: 'M1094.94 40.5447L1112.36 30.4964L1129.77 40.5447V60.6406L1112.36 70.6889L1094.94 60.6406V40.5447Z',
    },
    {
      d: 'M1094.94 100.26L1112.36 90.2121L1129.77 100.26V120.356L1112.36 130.405L1094.94 120.356V100.26Z',
    },
    {
      d: 'M1094.94 159.976L1112.36 149.928L1129.77 159.976V180.072L1112.36 190.12L1094.94 180.072V159.976Z',
    },
    {
      d: 'M1112.08 10.6867L1129.5 0.638343L1146.91 10.6867V30.7826L1129.5 40.8309L1112.08 30.7826V10.6867Z',
    },
    {
      d: 'M1112.08 70.4025L1129.5 60.3542L1146.91 70.4025V90.4984L1129.5 100.547L1112.08 90.4984V70.4025Z',
    },
    {
      d: 'M1112.08 130.118L1129.5 120.07L1146.91 130.118V150.214L1129.5 160.262L1112.08 150.214V130.118Z',
    },
    {
      d: 'M1129.77 40.5447L1147.19 30.4964L1164.61 40.5447V60.6406L1147.19 70.6889L1129.77 60.6406V40.5447Z',
    },
    {
      d: 'M1129.77 100.26L1147.19 90.2121L1164.61 100.26V120.356L1147.19 130.405L1129.77 120.356V100.26Z',
    },
    {
      d: 'M1129.77 159.976L1147.19 149.928L1164.61 159.976V180.072L1147.19 190.12L1129.77 180.072V159.976Z',
    },
    {
      d: 'M1146.91 10.6867L1164.33 0.638343L1181.75 10.6867V30.7826L1164.33 40.8309L1146.91 30.7826V10.6867Z',
    },
    {
      d: 'M1146.91 70.4025L1164.33 60.3542L1181.75 70.4025V90.4984L1164.33 100.547L1146.91 90.4984V70.4025Z',
    },
    {
      d: 'M1146.91 130.118L1164.33 120.07L1181.75 130.118V150.214L1164.33 160.262L1146.91 150.214V130.118Z',
    },
    {
      d: 'M1164.61 40.5447L1182.02 30.4964L1199.44 40.5447V60.6406L1182.02 70.6889L1164.61 60.6406V40.5447Z',
    },
    {
      d: 'M1164.61 100.26L1182.02 90.2121L1199.44 100.26V120.356L1182.02 130.405L1164.61 120.356V100.26Z',
    },
    {
      d: 'M1164.61 159.976L1182.02 149.928L1199.44 159.976V180.072L1182.02 190.12L1164.61 180.072V159.976Z',
    },
    {
      d: 'M1181.75 10.6867L1199.17 0.638343L1216.58 10.6867V30.7826L1199.17 40.8309L1181.75 30.7826V10.6867Z',
    },
    {
      d: 'M1181.75 70.4025L1199.17 60.3542L1216.58 70.4025V90.4984L1199.17 100.547L1181.75 90.4984V70.4025Z',
    },
    {
      d: 'M1181.75 130.118L1199.17 120.07L1216.58 130.118V150.214L1199.17 160.262L1181.75 150.214V130.118Z',
    },
    {
      d: 'M1199.44 40.5447L1216.86 30.4964L1234.28 40.5447V60.6406L1216.86 70.6889L1199.44 60.6406V40.5447Z',
    },
    {
      d: 'M1199.44 100.26L1216.86 90.2121L1234.28 100.26V120.356L1216.86 130.405L1199.44 120.356V100.26Z',
    },
    {
      d: 'M1199.44 159.976L1216.86 149.928L1234.28 159.976V180.072L1216.86 190.12L1199.44 180.072V159.976Z',
    },
    {
      d: 'M1216.58 10.6867L1234 0.638343L1251.42 10.6867V30.7826L1234 40.8309L1216.58 30.7826V10.6867Z',
    },
    {
      d: 'M1216.58 70.4025L1234 60.3542L1251.42 70.4025V90.4984L1234 100.547L1216.58 90.4984V70.4025Z',
    },
    {
      d: 'M1216.58 130.118L1234 120.07L1251.42 130.118V150.214L1234 160.262L1216.58 150.214V130.118Z',
    },
    {
      d: 'M1234.28 40.5447L1251.69 30.4964L1269.11 40.5447V60.6406L1251.69 70.6889L1234.28 60.6406V40.5447Z',
    },
    {
      d: 'M1234.28 100.26L1251.69 90.2121L1269.11 100.26V120.356L1251.69 130.405L1234.28 120.356V100.26Z',
    },
    {
      d: 'M1234.28 159.976L1251.69 149.928L1269.11 159.976V180.072L1251.69 190.12L1234.28 180.072V159.976Z',
    },
    {
      d: 'M1251.42 10.6867L1268.83 0.638343L1286.25 10.6867V30.7826L1268.83 40.8309L1251.42 30.7826V10.6867Z',
    },
    {
      d: 'M1251.42 70.4025L1268.83 60.3542L1286.25 70.4025V90.4984L1268.83 100.547L1251.42 90.4984V70.4025Z',
    },
    {
      d: 'M1251.42 130.118L1268.83 120.07L1286.25 130.118V150.214L1268.83 160.262L1251.42 150.214V130.118Z',
    },
    {
      d: 'M1269.11 40.5447L1286.53 30.4964L1303.94 40.5447V60.6406L1286.53 70.6889L1269.11 60.6406V40.5447Z',
    },
    {
      d: 'M1269.11 100.26L1286.53 90.2121L1303.94 100.26V120.356L1286.53 130.405L1269.11 120.356V100.26Z',
    },
    {
      d: 'M1269.11 159.976L1286.53 149.928L1303.94 159.976V180.072L1286.53 190.12L1269.11 180.072V159.976Z',
    },
    {
      d: 'M1286.25 10.6867L1303.67 0.638343L1321.08 10.6867V30.7826L1303.67 40.8309L1286.25 30.7826V10.6867Z',
    },
    {
      d: 'M1286.25 70.4025L1303.67 60.3542L1321.08 70.4025V90.4984L1303.67 100.547L1286.25 90.4984V70.4025Z',
    },
    {
      d: 'M1286.25 130.118L1303.67 120.07L1321.08 130.118V150.214L1303.67 160.262L1286.25 150.214V130.118Z',
    },
    {
      d: 'M1303.94 40.5447L1321.36 30.4964L1338.78 40.5447V60.6406L1321.36 70.6889L1303.94 60.6406V40.5447Z',
    },
    {
      d: 'M1303.94 100.26L1321.36 90.2121L1338.78 100.26V120.356L1321.36 130.405L1303.94 120.356V100.26Z',
    },
    {
      d: 'M1303.94 159.976L1321.36 149.928L1338.78 159.976V180.072L1321.36 190.12L1303.94 180.072V159.976Z',
    },
    {
      d: 'M1321.08 10.6867L1338.5 0.638343L1355.92 10.6867V30.7826L1338.5 40.8309L1321.08 30.7826V10.6867Z',
    },
    {
      d: 'M1321.08 70.4025L1338.5 60.3542L1355.92 70.4025V90.4984L1338.5 100.547L1321.08 90.4984V70.4025Z',
    },
    {
      d: 'M1321.08 130.118L1338.5 120.07L1355.92 130.118V150.214L1338.5 160.262L1321.08 150.214V130.118Z',
    },
    {
      d: 'M1338.78 40.5447L1356.19 30.4964L1373.61 40.5447V60.6406L1356.19 70.6889L1338.78 60.6406V40.5447Z',
    },
    {
      d: 'M1338.78 100.26L1356.19 90.2121L1373.61 100.26V120.356L1356.19 130.405L1338.78 120.356V100.26Z',
    },
    {
      d: 'M1338.78 159.976L1356.19 149.928L1373.61 159.976V180.072L1356.19 190.12L1338.78 180.072V159.976Z',
    },
    {
      d: 'M1355.92 10.6867L1373.34 0.638343L1390.75 10.6867V30.7826L1373.34 40.8309L1355.92 30.7826V10.6867Z',
    },
    {
      d: 'M1355.92 70.4025L1373.34 60.3542L1390.75 70.4025V90.4984L1373.34 100.547L1355.92 90.4984V70.4025Z',
    },
    {
      d: 'M1355.92 130.118L1373.34 120.07L1390.75 130.118V150.214L1373.34 160.262L1355.92 150.214V130.118Z',
    },
    {
      d: 'M1373.61 40.5447L1391.03 30.4964L1408.45 40.5447V60.6406L1391.03 70.6889L1373.61 60.6406V40.5447Z',
    },
    {
      d: 'M1373.61 100.26L1391.03 90.2121L1408.45 100.26V120.356L1391.03 130.405L1373.61 120.356V100.26Z',
    },
    {
      d: 'M1373.61 159.976L1391.03 149.928L1408.45 159.976V180.072L1391.03 190.12L1373.61 180.072V159.976Z',
    },
    {
      d: 'M1390.75 10.6867L1408.17 0.638343L1425.59 10.6867V30.7826L1408.17 40.8309L1390.75 30.7826V10.6867Z',
    },
    {
      d: 'M1390.75 70.4025L1408.17 60.3542L1425.59 70.4025V90.4984L1408.17 100.547L1390.75 90.4984V70.4025Z',
    },
    {
      d: 'M1390.75 130.118L1408.17 120.07L1425.59 130.118V150.214L1408.17 160.262L1390.75 150.214V130.118Z',
    },
    {
      d: 'M1408.45 40.5447L1425.86 30.4964L1443.28 40.5447V60.6406L1425.86 70.6889L1408.45 60.6406V40.5447Z',
    },
    {
      d: 'M1408.45 100.26L1425.86 90.2121L1443.28 100.26V120.356L1425.86 130.405L1408.45 120.356V100.26Z',
    },
    {
      d: 'M1408.45 159.976L1425.86 149.928L1443.28 159.976V180.072L1425.86 190.12L1408.45 180.072V159.976Z',
    },
    {
      d: 'M1425.59 10.6867L1443.01 0.638343L1460.42 10.6867V30.7826L1443.01 40.8309L1425.59 30.7826V10.6867Z',
    },
    {
      d: 'M1425.59 70.4025L1443.01 60.3542L1460.42 70.4025V90.4984L1443.01 100.547L1425.59 90.4984V70.4025Z',
    },
    {
      d: 'M1425.59 130.118L1443.01 120.07L1460.42 130.118V150.214L1443.01 160.262L1425.59 150.214V130.118Z',
    },
  ]

  return (
    <div className="mb-[50px] flex w-full justify-center">
      <svg
        preserveAspectRatio="xMinYMin slice"
        viewBox="0 0 1440 191"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {paths.map((path, idx) => (
          <path
            key={idx}
            d={path.d}
            fill={filledPaths.includes(idx) ? '#353839' : 'transparent'}
            stroke="#353839"
            strokeWidth="1.10585"
            className="cursor-pointer transition-colors duration-300"
            onMouseEnter={() => handleMouseEnter(idx)}
          />
        ))}
      </svg>
    </div>
  )
}

export default KingsCrossFooter
